(function (body, window, location, navigator) {
    const refreshTheme = () => {

        const localTheme = window.localStorage.getItem('keps_theme') || 'chill',
            checkbox_theme = [...document.querySelectorAll('.apparence .keps_theme')];

        if (checkbox_theme.length) {
            const input = checkbox_theme.find(node => node.value === localTheme) || checkbox_theme.at(0);
            if (input && !input.checked) {
                input.checked = true;
            }
        }

        for (let className of body.classList) {
            if (className.startsWith('theme_')) {
                body.classList.remove(className);
            }
        }
        body.classList.add(`theme_${localTheme}`);
    };
    window.addEventListener("storage", refreshTheme);
    refreshTheme();

    document.addEventListener('change', function (e) {
        const input = e.target.closest('input.keps_theme');
        if (!input || !input.checked) return;
        window.localStorage.setItem('keps_theme', input.value);
        refreshTheme();

        const isIOS = /Mac|iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        if(isIOS) {
            location.reload(true);
        }
    });
})(document.body, window, location, navigator);
